/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ScopeCheckFn } from "../types/ScopeCheckFn";
import { Scope } from "../types/Scope";

export function allOf(...requirements: (Scope | string | ScopeCheckFn)[]) : ScopeCheckFn {
  // array.every returns true for empty array
  return (scopesFromToken: (Scope | string)[]) => requirements.every(
      requirement => typeof requirement === "function" ? requirement(scopesFromToken) : scopesFromToken.includes(requirement)
  );
}

export function anyOf(...requirements: (Scope | string | ScopeCheckFn)[]): ScopeCheckFn {
  // array.some returns false for empty array
  return (scopesFromToken: (Scope | string)[]) => requirements.length === 0
      || requirements.some(
          requirement => typeof requirement === "function" ? requirement(scopesFromToken) : scopesFromToken.includes(requirement)
      );
}

export function not(requirement: (Scope | string | ScopeCheckFn)): ScopeCheckFn {
  return (scopesFromToken: (Scope | string)[]) =>
      typeof requirement === "function" ? !requirement(scopesFromToken) : !scopesFromToken.includes(requirement);
}

export const allow: ScopeCheckFn = () => true;

export const deny: ScopeCheckFn = () => false;
