/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


const showLog = (): boolean => {
  const params = new URLSearchParams(window.location.search)

  switch (params.get("logger")) {
    case "enable":
      sessionStorage.setItem("logging", JSON.stringify(true));
      return true;
    case "disable":
      sessionStorage.setItem("logging", JSON.stringify(false));
      return false;
    default:
      return JSON.parse(sessionStorage.getItem("logging") || JSON.stringify(false));
  }
};

export const logger = {
  isEnabled: showLog,
  log: (...optionalParams: unknown[]) => showLog() && console.log("[]", ...optionalParams), //NOSONAR typescript:S106 console.log ist hier ok
  warn: (...optionalParams: unknown[]) => showLog() && console.warn("[]", ...optionalParams), //NOSONAR typescript:S106 console.warn ist hier ok
  error: (...optionalParams: unknown[]) => console.error("[]", ...optionalParams) //NOSONAR typescript:S106 console.error ist hier ok
};
