/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import Keycloak, { KeycloakLoginOptions } from "keycloak-js";
import { logger } from "./utils/logger";
import { action, computed, makeObservable, observable } from "mobx";
import { AccessToken } from "./types/AccessToken";
import { AuthenticationManager } from "./types/AuthenticationManager";
import { Language } from "./types/Language";
import { AuthenticationEvent } from "./types/AuthenticationEvent";
import { AuthenticationEventListener } from "./types/AuthenticationEventListener";
import { LocaleManager } from "./types/LocaleManager";
import { defaultLocaleManager } from "./localeManager/defaultLocaleManager";
import { Scope } from "./types/Scope";
import { allOf } from "./utils/scopeChecks";
import { ScopeCheckFn } from "./types/ScopeCheckFn";
import { ActionUpdateStatus } from "./types/ActionUpdateStatus";
import { Acr } from "./types/Acr";
import { LoginOptions } from "./types/LoginOptions";

const LOG_MODULE = "[AuthenticationManager]";

export class AuthenticationManagerImpl2 implements AuthenticationManager {
  public authenticatedSubject?: string = undefined;
  public userId?: number = undefined;
  private mode?: "internal" | "external" = undefined;

  public get authenticated() {
    return this.authenticatedSubject !== undefined;
  }

  public get isInternal() {
    return this.mode === "internal";
  }

  public get isExternal() {
    return this.mode === "external";
  }

  public get language(): Language {
    return this.localeManager.language;
  }

  public set language(language: Language) {
    this.localeManager.language = language;
  }

  private readonly keycloak: Keycloak;
  private refreshTokenTimeoutHandle: NodeJS.Timeout | undefined = undefined;

  private readonly authenticationEventListeners: {
    [Event in AuthenticationEvent]: Set<AuthenticationEventListener<Event>>
  } = {
    onReady: new Set(),
    onAuthSuccess: new Set(),
    onAuthError: new Set(),
    onAuthRefreshSuccess: new Set(),
    onAuthRefreshError: new Set(),
    onAuthLogout: new Set(),
    onManualLogout: new Set(),
    onAccessTokenExpired: new Set(),
    onRefreshTokenExpired: new Set()
  };

  private _lastActionUpdateStatus?: ActionUpdateStatus = undefined;

  get lastActionUpdateStatus() {
    return this._lastActionUpdateStatus;
  }

  /**
   * Constructor.
   * The substring {lng} in logoutRedirectUriTemplate and onRefreshTokenExpiredRedirectUriTemplate will be replaced with the current user language.
   * @param {string} url Keycloak URL (incl. /auth)
   * @param {string} realm Keycloak Realm
   * @param {string} clientId Keycloak Client
   * @param {string} logoutRedirectUriTemplate template for logout redirect uri
   * @param localeManager locale manager
   */
  constructor(
      private readonly url: string,
      private readonly realm: string,
      private readonly clientId: string,
      private readonly logoutRedirectUriTemplate: string = window.location.origin,
      private readonly localeManager: LocaleManager = defaultLocaleManager
  ) {
    const onRefreshTokenExpired = action(async () => {
      logger.log(LOG_MODULE, "onRefreshTokenExpired for authenticatedSubject", this.authenticatedSubject);
      this.authenticationEventListeners.onRefreshTokenExpired.forEach(callback => callback());
      await this.triggerOnAuthLogoutEventListeners();
      window.location.href = this.logoutRedirectUri;
    });

    const onAuthOrAuthRefreshSuccess = action(() => {
      logger.log(LOG_MODULE, "onAuthOrAuthRefreshSuccess");
      if (this.refreshTokenTimeoutHandle) {
        clearTimeout(this.refreshTokenTimeoutHandle);
        this.refreshTokenTimeoutHandle = undefined;
      }
      //copied from keycloak.js (version 22.0.0) lines 1679ff
      const refreshTokenExpiresIn = (this.keycloak.refreshTokenParsed?.exp! - (new Date().getTime() / 1000) + (this.keycloak.timeSkew || 0)) * 1000;
      logger.log(LOG_MODULE, `refresh token expires in ${Math.round(refreshTokenExpiresIn / 1000)} s`);
      if (refreshTokenExpiresIn <= 0) {
        // noinspection JSIgnoredPromiseFromCall
        onRefreshTokenExpired();
      } else {
        this.refreshTokenTimeoutHandle = setTimeout(onRefreshTokenExpired, refreshTokenExpiresIn);
      }
      const accessToken = this.keycloak.tokenParsed as AccessToken
      this.authenticatedSubject = accessToken.sub;
      this.userId = Number(accessToken.user_id);
      if(accessToken.scope.includes("internal")) {
        this.mode = "internal";
      } else if(accessToken.scope.includes("external")) {
        this.mode = "external";
      } else {
        this.mode = undefined
      }
      this.localeManager.language = accessToken.language;
    });

    this.keycloak = new Keycloak({
      url: this.url,
      realm: this.realm,
      clientId: this.clientId
    });

    this.keycloak.onReady = (authenticated: boolean = false) => {
      logger.log(LOG_MODULE, "onReady isAuthenticated:", authenticated);
      this.authenticationEventListeners.onReady.forEach(callback => callback(authenticated));
    };

    this.keycloak.onAuthSuccess = () => {
      logger.log(LOG_MODULE, "onAuthSuccess");
      onAuthOrAuthRefreshSuccess();
      this.authenticationEventListeners.onAuthSuccess.forEach(callback => callback());
    };

    this.keycloak.onAuthError = () => {
      logger.log(LOG_MODULE, "onAuthError");
      this.authenticationEventListeners.onAuthError.forEach(callback => callback());
    };

    this.keycloak.onAuthRefreshSuccess = () => {
      logger.log(LOG_MODULE, "onAuthRefreshSuccess");
      onAuthOrAuthRefreshSuccess();
      this.authenticationEventListeners.onAuthRefreshSuccess.forEach(callback => callback());
    };

    this.keycloak.onAuthRefreshError = () => {
      logger.log(LOG_MODULE, "onAuthRefreshError");
      this.authenticationEventListeners.onAuthRefreshError.forEach(callback => callback());
    };

    //only called when session status iframe detects a logout (e.g. session expired or user logs out in another tab)
    //but: refresh token expiration is detected and handled in onAuthOrAuthRefreshSuccess above
    this.keycloak.onAuthLogout = action(async () => {
      logger.log(LOG_MODULE, "onAuthLogout for authenticatedSubject", this.authenticatedSubject);
      await this.triggerOnAuthLogoutEventListeners();
      window.location.href = this.logoutRedirectUri;
    });

    this.keycloak.onTokenExpired = action(() => {
      logger.log(LOG_MODULE, "onAccessTokenExpired");
      this.authenticationEventListeners.onAccessTokenExpired.forEach(callback => callback());
    });

    this.keycloak.onActionUpdate = action((status: ActionUpdateStatus) => {
      logger.log(LOG_MODULE, "onActionUpdate", status);
      this._lastActionUpdateStatus = status;
    });

    makeObservable<AuthenticationManagerImpl2, "_lastActionUpdateStatus" | "mode">(this, {
      authenticatedSubject: observable,
      _lastActionUpdateStatus: observable,
      mode: observable,
      userId: observable,
      isExternal: computed,
      isInternal: computed,
      authenticated: computed,
      language: computed,
      lastActionUpdateStatus: computed,
    });
  }

  public init() {
    logger.log(LOG_MODULE, "Initializing");
    return this.keycloak
        .init({
          onLoad: "check-sso",
          checkLoginIframe: true,
          silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
          enableLogging: logger.isEnabled(),
          pkceMethod: "S256"
        });
  }

  public addEventListener<T extends AuthenticationEvent>(event: T, listener: AuthenticationEventListener<T>): () => void {
    this.authenticationEventListeners[event].add(listener);
    return () => this.removeEventListener(event, listener);
  }

  public removeEventListener<T extends AuthenticationEvent>(event: T, listener: AuthenticationEventListener<T>): void {
    this.authenticationEventListeners[event].delete(listener);
  }

  private async triggerOnAuthLogoutEventListeners() {
    logger.log(LOG_MODULE, "triggerOnAuthLogoutEventListeners");
    //trigger onAuthLogout event listeners and wait for async completion
    await Promise.allSettled(Array.from(this.authenticationEventListeners.onAuthLogout).map(listener => Promise.resolve(listener())));
  }

  public login(loginOptions: LoginOptions = {}) {
    logger.log(LOG_MODULE, "Logging in user with authorization code flow");
    const {acr, ...optionsRest} = loginOptions;
    const options: KeycloakLoginOptions = {
      ...optionsRest,
      locale: this.localeManager.language === "de" ? "de-DE" : "en-GB"
    };
    if (acr !== undefined) {
      options.acr = {values: acr, essential: true};
    }
    return this.keycloak.login(options);
  }

  public updatePassword(): Promise<void> {
    logger.log(LOG_MODULE, "redirect to update password");
    return this.keycloak.login({
      locale: this.localeManager.language === "de" ? "de-DE" : "en-GB",
      action: "UPDATE_PASSWORD",
      redirectUri: `${window.location.origin}/user/passwordchangesuccess`
    });
  }

  public configureTotp(): Promise<void> {
    logger.log(LOG_MODULE, "redirect to configure TOTP");
    return this.keycloak.login({
      locale: this.localeManager.language === "de" ? "de-DE" : "en-GB",
      action: "CONFIGURE_TOTP",
      redirectUri: `${window.location.origin}/user/twofactorauthentication/success`
    });
  }

  public configureSecurityProfile(): Promise<void> {
    logger.log(LOG_MODULE, "redirect to configure security profile");
    return this.keycloak.login({
      locale: this.localeManager.language === "de" ? "de-DE" : "en-GB",
      action: "CONFIGURE_SECURITY_PROFILE",
      redirectUri: `${window.location.origin}/user/twofactorauthentication/securityProfileSuccess`
    });
  }

  private get logoutRedirectUri() {
    return this.logoutRedirectUriTemplate.replace("{lng}", this.localeManager.language);
  }

  public async logout(redirectUri: string = this.logoutRedirectUri) {
    logger.log(LOG_MODULE, "logout");
    this.authenticationEventListeners.onManualLogout.forEach(callback => callback());
    await this.triggerOnAuthLogoutEventListeners();
    await this.keycloak.logout({redirectUri});
  }

  public async updateToken(minValiditySeconds: number = 5): Promise<boolean> {
    return this.keycloak.updateToken(minValiditySeconds);
  }

  public isTokenExpired(minValiditySeconds?: number) {
    return this.keycloak.isTokenExpired(minValiditySeconds);
  }

  async getAccessToken(minValiditySeconds: number = 3): Promise<string | undefined> {
    try {
      await this.updateToken(minValiditySeconds);
    } catch (e) {
      logger.log(LOG_MODULE, "getAccessToken: refresh failed:", e);
    }
    return this.keycloak.token;
  }

  getAccessTokenWithoutRefresh(): string | undefined {
    return this.keycloak.token;
  }

  async getAccessTokenParsed(minValiditySeconds: number = 3): Promise<AccessToken> {
    try {
      await this.updateToken(minValiditySeconds);
    } catch (e) {
      logger.log(LOG_MODULE, "getAccessTokenParsed: refresh failed:", e);
    }
    if (this.keycloak.tokenParsed !== undefined) {
      return this.keycloak.tokenParsed as AccessToken;
    } else {
      throw new Error(`${LOG_MODULE} authentication refresh failed`);
    }
  }

  getRefreshTokenParsed(): AccessToken {
    if (this.keycloak.refreshTokenParsed !== undefined) {
      return this.keycloak.refreshTokenParsed as AccessToken;
    } else {
      throw new Error(`${LOG_MODULE} no refresh token present`);
    }
  }

  async getUserId(): Promise<number> {
    try {
      const accessToken = await this.getAccessTokenParsed();
      return Number(accessToken.user_id);
    } catch (e) {
      logger.log(LOG_MODULE, "getUserId: refresh failed:", e);
      throw e;
    }
  }

  async getScopes(): Promise<(Scope | string)[]> {
    try {
      const accessToken = await this.getAccessTokenParsed();
      return accessToken.scope.split(" ");
    } catch (e) {
      logger.log(LOG_MODULE, "getScopes: refresh failed:", e);
      if (e !== null) {
        throw e;
      } else {
        throw new Error(`${LOG_MODULE} getScopes: refresh failed`);
      }
    }
  }

  //TODO when to remove?
  async hasScopes(...scopes: string[]): Promise<boolean> {
    return await this.scopeCheck(allOf(...scopes));
  }

  async scopeCheck(requirement: Scope | string | ScopeCheckFn): Promise<boolean> {
    try {
      const scopesFromToken = await this.getScopes();
      return typeof requirement === "function" ? requirement(scopesFromToken) : scopesFromToken.includes(requirement);
    } catch (e) {
      logger.log("scopeCheck: error getting scopes:", e);
      throw e;
    }
  }

  async acrCheck(requirement: Acr): Promise<boolean> {
    const accessToken = await this.getAccessTokenParsed(1);
    return accessToken.acr_exp !== undefined && accessToken.acr_exp >= (Date.now() / 1000) && accessToken.acr === requirement;
  }

  public getAuthorizationDataForIframe() {
    return {
      keycloakUrl: this.url,
      keycloakRealm: this.realm,
      clientId: this.clientId,
      accessToken: this.keycloak.token!,
      idToken: this.keycloak.idToken!,
      refreshToken: this.keycloak.refreshToken!
    };
  }
}
