/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { logger } from "../utils/logger";
import { Cookie } from "storage-manager-js";
import { Language } from "../types/Language";
import { LocaleManager } from "../types/LocaleManager";
import { computed, makeObservable, observable, runInAction } from "mobx";

const LOG_MODULE = "[CookieLocaleManager]";
const LANGUAGE_COOKIE_NAME: string = "gkpLanguage";

export class CookieLocaleManager implements LocaleManager {
  private _language: Language = CookieLocaleManager.getLanguageFromCookie();

  constructor() {
    makeObservable<CookieLocaleManager, "_language">(this, {
      _language: observable,
      language: computed
    });
  }

  public get language(): Language {
    runInAction(() => this._language = CookieLocaleManager.getLanguageFromCookie());
    return this._language;
  }

  public set language(language: Language) {
    CookieLocaleManager.writeLanguageToCookie(language);
    this._language = language;
  }

  private static getLanguageFromCookie(): Language {
    const language = Cookie.get<Language>(LANGUAGE_COOKIE_NAME) as (Language | null);
    logger.log(LOG_MODULE, "Read language", language, "from cookie", LANGUAGE_COOKIE_NAME);
    return language || "de";
  }

  private static writeLanguageToCookie(language: Language) {
    Cookie.set(
        LANGUAGE_COOKIE_NAME,
        language,
        {expires: 30}
    );
    logger.log(LOG_MODULE, "Wrote language", language, "to cookie", LANGUAGE_COOKIE_NAME);
  }
}
